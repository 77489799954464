import { useLockFn, useMount } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useEffect, useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import Image from "../general/Image";
import useActiveState from "../general/useActiveState";
import dayjs from "dayjs";

export default () => {
  const [app] = useActiveState("app");
  const [params] = useUrlState();
  const [data, setData] = useState(general.rebate || {});
  const [roleId, setRoleId] = useState();
  const [datas, setDatas] = useState();
  useMount(() => {
    axios
      .get(`/role/roleList`)
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        setDatas(resData.data.roleList);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    setRoleId(app.roleId);
  }, [app.roleId]);
  useEffect(() => {
    Dialog.loading();
    axios
      .get(`/activityRebate/gameActivityInfo?id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        setData(resData.data);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  }, []);

  const receive = useLockFn(async () => {
    if (!data.isSureApply || data.isApplyFinish || !data.is_apply_rebate) {
      return;
    }
    Dialog.loading();
    await axios
      .post("/activityRebate/gameActivityApply", {
        id: data.newid,
        role_id: roleId,
      })
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        Dialog.success("申请成功");
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  return (
    <div className="rebate-rebate">
      <div className="body">
        <div className="head">
          <div className="icon">
            <Image src={data.logo} />
          </div>
          <div className="info">
            <div className="name">{data.title}</div>
            <div className="desc">{data.description}</div>
          </div>
        </div>
        <div
          className="data"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        <div className="require">
          {/* 满足条件还差的充值金额：<span>¥{data.diffAmount}</span>
          <br /> */}
          充值累计时间：
          {data.start_time
            ? dayjs.unix(data.start_time).format("YYYY-MM-DD") + "~"
            : null}
          {data.deadline == 1
            ? "永久"
            : dayjs.unix(data.deadline).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="foot">
        {datas && data.is_apply_rebate && !data.isApplyFinish ? (
          <div className="select">
            <span>选择区服角色：</span>
            <select onChange={(e) => setRoleId(e.target.value)}>
              <option value="">请选择区服角色</option>
              {datas.map((item) => (
                <option value={item.roleid} selected={item.roleid == roleId}>
                  {item.zonename} - {item.rolename}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div
          className={`operate ${!data.is_apply_rebate ? "auto" : ""}  ${
            data.isApplyFinish ? "complete" : ""
          } ${!data.isSureApply ? "nothing" : ""}`}
          onClick={receive}
        />
      </div>
    </div>
  );
};
