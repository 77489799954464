import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Active from "../general/Active";

export default ({ router }) => {
  const location = useLocation();
  const [current, setCurrent] = useState(router.views[0]);
  useEffect(() => {
    let current = router.views.find((item) =>
      location.pathname.startsWith(item.path)
    );
    current && setCurrent(current);
  }, [location]);

  return (
    <div className="account-index">
      <div className="account-head">
        {router?.views?.map((item) => (
          <Link
            to={item.path}
            replace
            className={`item ${item.id} ${item == current ? "active" : ""}`}
          >
            {item.name}
          </Link>
        ))}
      </div>
      <div className="account-body">
        {router?.views?.map((item) => (
          <Active
            className={`account-item ${item.id}`}
            active={item == current}
          >
            <item.view />
          </Active>
        ))}
      </div>
    </div>
  );
};
