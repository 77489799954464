import Dialog from "./Dialog";
import React, {useState} from "react";
import {useBoolean, useLockFn} from "ahooks";
import axios from "../../axios";

export default () => {
  const [test, {setTrue: testTrue, setFalse: testFalse}] = useBoolean();
  const [param, setParam] = useState();
  const handle = useLockFn(async () => {
    Dialog.loading();
    await axios.get('/xh/test', {params: {param}})
      .finally(() => {
        Dialog.close();
      })
      .then(({data: resData}) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        Dialog.loading(resData.data.altUsername);
        setTimeout(() => {
          window.location.href = `sdk://enter-game?altUsername=${resData.data.altUsername}&auto=`;
          testFalse();
          Dialog.close();
        }, 512);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  return <>
    <div className="test" onClick={testTrue}>test</div>
    <Dialog.Dialog title="测试" mask={true} show={test} btns={[
      {text: '取消', onPress: testFalse},
      {text: '确定', onPress: handle}
    ]}>
      <div className="test-from">
        <input type="text" placeholder="输入小号用户名或者交易订单号" value={param} onChange={e => setParam(e.target.value)}/>
      </div>
    </Dialog.Dialog>
  </>;
}
