import {useLockFn, useMount, useUpdate} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import {useState} from "react";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";

export default () => {
  const update = useUpdate();
  const [data, setData] = useState(general.voucher || {});
  const [params] = useUrlState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios.get(`/coupon/couponInfo?id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setData(res.data.data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const receive = useLockFn(async () => {
    if (data.status == 2 || data.surplus_total < 1) {
      return;
    }
    Dialog.loading();
    data.receiving = true;
    update();
    await axios.post('/coupon/gameCouponReceive', {id: data.id})
      .finally(() => {
        Dialog.close();
        data.receiving = false;
        update();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success('领取成功');
        data.status = 2;
        general.voucher.status = 2;
        update();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="voucher-voucher">
    <div className="head">
      <div className="amount">
        <div className="value">
          <div className="unit">￥</div>
          <div className="number">{data.amount * 1}</div>
        </div>
        <div className="desc">
          {data.meet_amount > 0 ? `满${data.meet_amount * 1}使用` : '任意金额使用'}
        </div>
      </div>
      <div className="info">
        <div className="data">
          <div className="name">
            <div className="type">{data.type}</div>
            <div className="named">{data.name}</div>
          </div>
          <div className="desc">{data.end_time}</div>
        </div>
      </div>
    </div>
    <div className="body">
      <table>
        <tr>
          <td>名称</td>
          <td>{data.name}</td>
        </tr>
        <tr>
          <td>金额</td>
          <td>¥{data.amount * 1}</td>
        </tr>
        <tr>
          <td>有效期</td>
          <td>{data.end_time}到期</td>
        </tr>
        <tr>
          <td>类型</td>
          <td>{data.type}</td>
        </tr>
        <tr>
          <td>适用游戏</td>
          <td>{data.apply_game}</td>
        </tr>
        <tr>
          <td>详情</td>
          <td>{data.details}</td>
        </tr>
      </table>
    </div>
    <div className="foot">
      <div className={`operate ${data.surplus_total < 1 ? 'nothing' : ''} ${data.receiving ? 'receiving' : ''} ${data.status == 2 ? 'received' : ''}`} onClick={() => receive()}/>
    </div>
  </div>;
}
