import React, {useState} from "react";
import {useLockFn, useMount} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import useActiveState from "../general/useActiveState";

export default () => {
  const [app] = useActiveState('app');
  const [alts, setAlts] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios.get('/xh/getXhList')
      .finally(() => {
        Dialog.close();
      })
      .then(({data: resData}) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        let alts = [...(resData.data.list.filter(alt => alt.xh_username === app.altUsername)), ...(resData.data.list.filter(alt => alt.xh_username !== app.altUsername))];
        setAlts(alts);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const [alt, setAlt] = useState();
  const save = useLockFn(async () => {
    Dialog.loading();
    await axios.post(alt.xh_id ? '/xh/updateXhName' : '/xh/addXh', alt.xh_id ? {xh_id: alt.xh_id, xh_name: alt.alias} : {xh_name: alt.alias})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setAlt(null);
        load();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  const update = useLockFn(async (alt) => {
    await axios.post('xh/updateDefaultXh', {xh_username: alt.xh_username, is_default: alt.is_default});
  });

  return <div className="user-alt">
    <div className="alt-title">最多可在游戏中创建10个小号，创建后不可删除</div>
    <div className="alt-items">
      {alts.map(alt => <div className={`item ${!alt.status ? 'disable' : ''} ${app.altUsername === alt.xh_username ? 'current' : ''} ${alt.is_default ? 'default' : ''}`}>
        <label className="select">
          <input
            type="checkbox"
            checked={alt.is_default}
            onChange={e => setAlts(alts => alts.map(item => {
              item.is_default = item.xh_id === alt.xh_id && e.target.checked ? 1 : 0;
              if (item.xh_id === alt.xh_id) {
                update(alt);
              }
              return item;
            }))}/>
          默认
        </label>
        <div className="name">{alt.alias}</div>
        <div className="opt">
          <div className="switch" onClick={() => {
            if (!alt.status) {
              Dialog.alert('该小号不可用 可能在交易中');
              return;
            }
            if (app.altUsername !== alt.xh_username) {
              Dialog.confirm({
                title: `确定切换小号(${alt.alias})吗？`,
                yes: () => {
                  update({...alt, is_default: 1});
                  window.location.href = `sdk://reenter-game?altUsername=${alt.xh_username}&auto=${alt.is_default}`;
                }
              })
            }
          }}>切换
          </div>
          <div className="modify" onClick={e => {
            if (!alt.status) {
              Dialog.alert('该小号不可用 可能在交易中');
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            setAlt({...alt});
          }}>修改
          </div>
        </div>
      </div>)}
    </div>
    <div className="alt-operate" onClick={() => setAlt({})}>添加小号</div>
    <Dialog.Dialog title={alt?.xh_id ? '修改名称' : '添加小号'} mask={true} show={!!alt} btns={[
      {text: '取消', onPress: () => setAlt(null)},
      {text: '确定', onPress: () => save()}
    ]}>
      <div className="alt-from">
        <div className="title">支持中英文和数字，不超过24个字符</div>
        <div className="input"><input type="text" placeholder="输入名称" value={alt?.alias} onChange={e => {
          setAlt({...alt, alias: e.target.value});
        }}/></div>
      </div>
    </Dialog.Dialog>
  </div>;
}
