import Dialog from "../general/Dialog";
import {useLockFn, useMount} from "ahooks";
import axios from "../../axios";
import {useState} from "react";
import Image from "../general/Image";
import Empty from "../general/Empty";
import SchemeLink from "../general/SchemeLink";
import {openBoxApp2} from "../general/Old";
import useActiveState from "../general/useActiveState";

export default () => {
  const [app] = useActiveState('app');
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/transaction/transactionList')
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return <div className="trade-trades">
    <div className="trade-head">
      <SchemeLink className="container" scheme={`box9917://trade`} onClick={app.branch === 'old' ? openBoxApp2 : null}>
        <div className="info">
          <div className="title">小号回收 立返平台币</div>
          <div className="desc">账号不想玩了？试试回收功能！</div>
        </div>
        <div className="operate">查看详情！</div>
      </SchemeLink>
    </div>
    <div className="trade-body">
      {datas.length > 0 ? <div className="items">
        {datas.map(item => <SchemeLink className="item" scheme={`box9917://trade/detail?id=${item.id}`}>
          <div className="head">
            <div className="time">上架时间：{item.trade_create_time}</div>
            <div className="price">{item.price * 1}元</div>
          </div>
          <div className="body">
            <div className="icon"><Image src={item.first_pic} alt=""/></div>
            <div className="info">
              <div className="name">{item.goods_title}</div>
              <div className="server">区服：{item.server_name}</div>
              <div className="game">{item.goods_title}</div>
            </div>
          </div>
        </SchemeLink>)}
      </div> : null}
      {datas.length == 0 && !loading ? <Empty/> : null}
    </div>
  </div>;
}
