import {useExternal} from "ahooks";
import {useEffect} from "react";
import config from "../../config";
import URI from "urijs";

export default () => {
  const [status] = useExternal('https://ssl.captcha.qq.com/TCaptcha.js');
  useEffect(() => {
    if (status === 'ready') {
      let captcha = new window.TencentCaptcha(config.tencentCaptcha.appid, res => {
        if (res.ret == 2) {
          window.location.href = 'sdk://close';
          return;
        }
        if (res.ret == 0) {
          window.location.href = URI('sdk://captcha')
            .addQuery('randstr', res.randstr)
            .addQuery('ticket', res.ticket)
            .toString();
         /* setTimeout(() => {
            window.location.href = 'sdk://close';
          }, 1993);*/
          return;
        }
      });
      captcha.show();
    }
  }, [status]);

  return status !== 'ready' ? status : null;
}
