import { useLockFn, useMount } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useContext, useEffect, useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import ActiveContext from "../general/ActiveContext";
import { openUrl } from "../general/Old";
import useActiveState from "../general/useActiveState";

export default () => {
  const [app] = useActiveState("app");
  const { setCdata } = useContext(ActiveContext);
  const [data, setData] = useState(general.msg || {});
  useEffect(() => {
    setCdata({ title: data.title });
  }, [data.title]);
  const [params] = useUrlState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios
      .get(`/msg/getMsgInfo?id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        try {
          general.msg.readOrNot = false;
        } catch (e) {}
        setData(res.data.data);
        setTimeout(() => {
          try {
            let $ = window.$;
            $(".user-msg .content a").click(function (e) {
              let url = $(this).attr("href");
              if (url.startsWith("https://work.weixin.qq.com")) {
                e.preventDefault();
                if (app.branch === "old") {
                  openUrl(url);
                } else {
                  window.location.href = `sdk://browser?url=${encodeURIComponent(
                    url
                  )}`;
                }
              }
            });
          } catch (e) {}
        }, 255);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const wxJump = () => {
    if (data.type == 1) {
      window.location.href = `sdk://browser?url=${encodeURIComponent(
        data.qr_code
      )}`;
    } else {
      if (app.username === "iosceshi" || app.username === "apkceshi") {
        axios.post("app/csButtonClick");
        if (data.qr_code.startsWith("weixin://")) {
          window.location.href = data.qr_code;
          return;
        }
      }
      if (app.branch === "old") {
        openUrl(data.qr_code);
      } else {
        window.location.href = `sdk://browser?url=${encodeURIComponent(
          data.qr_code
        )}`;
      }
    }
  };

  return (
    <div className="user-msg">
      <div className="body">
        {data.qr_code ? (
          <div className="title" onClick={wxJump}>
            <span>联系客服</span>
          </div>
        ) : null}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
      <div className="time">{data.create_time}</div>
    </div>
  );
};
