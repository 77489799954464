import axios from "../../axios";
import {
  LoadingOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Dialog from "../general/Dialog";
import { useLockFn, useMount } from "ahooks";
import { Link, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import ActiveContext from "../general/ActiveContext";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import useActiveState from "../general/useActiveState";
import Image from "../general/Image";
import Test from "../general/Test";
import useReactive from "../general/useReactive";
import compareVersions from "compare-versions";
import Offline from "../general/Offline";
import Newuser from "../general/Newuser";

export const AltAlt = () => {
  const history = useHistory();
  const [params] = useUrlState();
  const { setCdata } = useContext(ActiveContext);
  const data = useReactive({ xh_id: params.id, xh_name: params.alias });
  useMount(() => {
    setCdata({ title: data.xh_id ? "修改名称" : "添加小号+" });
  });

  const [saving, setSaving] = useState();
  const save = useLockFn(async () => {
    setSaving(true);
    await axios
      .post(data.xh_id ? "/xh/updateXhName" : "/xh/addXh", data)
      .finally(() => {
        Dialog.close();
        setSaving(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success("操作成功", () => {
          /*history.goBack();*/
          history.push("/alt");
          general.load();
        });
      })
      .catch((error) => Dialog.error(error.message));
  });

  return (
    <div className="alt-alt">
      <div className="body">
        <div className="tips">支持中英文和数字，不超过24个字符</div>
        <input
          type="text"
          placeholder="请输入小号名称"
          value={data.xh_name}
          onChange={(e) => (data.xh_name = e.target.value)}
        />
      </div>
      <div className={`operate ${saving ? " saving" : ""}`} onClick={save}>
        <LoadingOutlined className="icon" />
        <div className="text">{params.id ? "保存" : "添加"}</div>
      </div>
    </div>
  );
};

export default () => {
  const history = useHistory();
  const { active, setCdata } = useContext(ActiveContext);
  const [app] = useActiveState("app");
  const [user, setUser] = useActiveState("user");
  const [alts, setAlts] = useState([]);
  const [data, setData] = useState({});
  const load = useLockFn(async () => {
    Dialog.loading();
    axios
      .get("/xh/getXhList")
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        resData = resData.data;
        setData(resData);
        console.log("resData==========", resData);
        setAlts(resData.list);
        setUser({
          username: app.username,
          avator: resData.userlogo,
          test: resData.test,
        });
        // 小号页面白屏的强制自动登录
        if (
          /android 11; (p|ASUS_)/i.test(navigator.userAgent) &&
          compareVersions(app.sdkVersion || "3.0.0", "3.10.1") < 0
        ) {
          if (!resData?.realname?.isAdult) {
            alert("请您在游戏盒子实名认证后再登录游戏！");
            window.location.href = "box9917://";
          } else {
            let item = resData.list[0];
            window.location.href = `sdk://enter-game?altUsername=${item.xh_username}&auto=1`;
          }
          return;
        }
        if (!resData?.realname?.isAdult) {
          Dialog.dialog({
            type: "modal",
            mask: true,
            title: "游客模式说明",
            content: "根据国家规定，未实名、未成年用户不能进入游戏",
            btns: [
              {
                text: "去认证",
                onPress: () => history.push("/realname"),
              },
              {
                text: "重新登录",
                onPress: () => (window.location.href = "sdk://login"),
              },
            ],
            style: { zIndex: 8001 },
          });
        }
      })
      .catch((error) => Dialog.error(error.message));
  });
  useEffect(() => {
    if (active) {
      load();
      general.load = load;
    }
  }, [active]);
  useEffect(() => {
    setCdata({
      head: (
        <>
          <div className="user">
            <div className="avatar">
              <Image src={user.avator} />
            </div>
            <div className="name">{user.username}</div>
          </div>
          <div
            className="operate"
            onClick={() =>
              app.branch == "h5"
                ? history.push("/login")
                : (window.location.href = "sdk://login")
            }
          >
            切换账号
          </div>
        </>
      ),
    });
  }, [user]);

  const update = useLockFn((alt) =>
    axios.post("xh/updateDefaultXh", {
      xh_username: alt.xh_username,
      is_default: alt.is_default,
    })
  );

  return (
    <>
      <div className="alt-index">
        <div className="alt-head">
          <div className="title">
            <div className="text">请选择账号登录</div>
            <QuestionCircleOutlined
              className="icon"
              onClick={() =>
                Dialog.alert(
                  "小号说明",
                  <>
                    1.每个游戏账号均可登录多款游戏
                    <br />
                    2.同一款游戏，可创建最多10个小号
                    <br />
                    3.每个游戏小号各自独立，等于同一平台账号里面每个游戏可创建多个小号
                    <br />
                  </>
                )
              }
            />
          </div>
          <div className="operate">
            <Link to="/alt/alt" className="add">
              <PlusCircleOutlined className="icon" />
              <div className="text">添加小号</div>
            </Link>
            {user.test ? <Test /> : null}
          </div>
        </div>
        <div className="alt-body">
          {alts.map((item) => (
            <div
              onClick={() => {
                if (item.status != 1) {
                  Dialog.alert("该小号暂不可用");
                  return;
                }
                if (app.branch == "h5") {
                  history.push(
                    `/game?altUsername=${item.xh_username}&autoLogin=${item.is_default}`
                  );
                } else {
                  window.location.href = `sdk://enter-game?altUsername=${item.xh_username}&auto=${item.is_default}`;
                }
              }}
              className={`alt ${!item.status ? "disable" : ""} ${
                item.is_default ? "active" : ""
              }`}
            >
              <div
                className="select"
                onClick={(e) => {
                  e.stopPropagation();
                  setAlts((alts) =>
                    alts.map((alt) => {
                      alt.is_default =
                        alt.xh_id === item.xh_id
                          ? item.is_default
                            ? 0
                            : 1
                          : 0;
                      if (alt.xh_id === item.xh_id) {
                        update(alt);
                      }
                      return alt;
                    })
                  );
                }}
              >
                <input type="checkbox" checked={item.is_default} />
                默认
              </div>
              <div className="name">{item.alias}</div>
              <Link
                to={`/alt/alt?id=${item.xh_id}&alias=${item.alias}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!item.status) {
                    e.preventDefault();
                    Dialog.alert("该小号不可用 可能在交易中");
                  }
                }}
                className="operate"
              >
                <div className="text">修改</div>
                <RightOutlined className="icon" />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {data.offline ? <Offline /> : data.newuser ? <Newuser /> : null}
    </>
  );
};
