import {useHistory, useLocation} from "react-router-dom";
import Active from "../general/Active";
import ActiveContext from "../general/ActiveContext";
import {useContext, useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import badgeState from "../general/badgeState";
import useActiveState from "../general/useActiveState";

export default ({router}) => {
  const location = useLocation();
  const history = useHistory();
  const [app] = useActiveState('app');
  const [badge, setBadge] = useRecoilState(badgeState);
  const active = useContext(ActiveContext);
  const [current, setCurrent] = useState(router.views[0]);
  useEffect(() => {
    let current = router.views.find(item => location.pathname.startsWith(item.path));
    current && setCurrent(current);
  }, [location]);

  return <div className="welfare-index">
    <div className="welfare-head">
      {router.views.filter(view => {
        return !(view.id === 'rebate' && app.branch === 'old');
      }).map(item => <div
        className={`item ${badge[item.id] ? 'badge' : ''} ${item.id} ${item == current ? 'active' : ''}`}
        onClick={() => {
          if (!location.pathname.startsWith(item.path)) {
            history.replace(item.path);
          }
        }}
        onDoubleClick={() => history.replace(item.path)}
      >
        <div className="text">{item.name}</div>
      </div>)}
    </div>
    <div className="welfare-body">
      {router.views.map(item => <Active className="welfare-item" active={active && item == current}>
        <item.view/>
      </Active>)}
    </div>
  </div>;
}
