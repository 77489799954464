import { Link, useHistory } from "react-router-dom";
import { useLocalStorageState } from "ahooks";
import { useState } from "react";
import axios from "../../axios";
import Dialog from "../general/Dialog";
import useActiveState from "../general/useActiveState";

export default () => {
  const history = useHistory();
  const [app, setApp] = useActiveState("app");
  const [user, setUser] = useLocalStorageState("account", {});
  const [data, setData] = useState(user);

  const submit = () => {
    Dialog.loading();
    axios
      .post("/user/login", data)
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: res }) => {
        if (res.code != 0) {
          Dialog.error(res.msg);
          return;
        }
        let resData = res.data;
        setUser(data);
        /*setApp({
          username: resData.username,
          token: resData.token,
        });*/
        history.push(
          resData.autoLogin && false
            ? `/game?altUsername=${resData.altUsername}`
            : "/alt"
        );
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  };

  return (
    <div className="account-password-login">
      <div className="tips">请输入账号密码</div>
      <div className="input">
        <div className="label">账号</div>
        <input
          type="text"
          placeholder="请输入用户名"
          value={data.username}
          onChange={(e) => setData({ ...data, username: e.target.value })}
        />
      </div>
      <div className="input">
        <div className="label">密码</div>
        <input
          type="password"
          placeholder="请输入密码"
          value={data.password}
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
        <Link className="opt" to="/forget">
          忘记密码？
        </Link>
      </div>
      <div className="btn" onClick={submit}>
        立即登录
      </div>
      <div className="agreement">
        登录即同意
        <Link to={`/agreement?url=${app.agreementList?.login?.url}`}>
          《用户协议》
        </Link>
        和
        <Link to={`/agreement?url=${app.agreementList?.privacy?.url}`}>
          《隐私政策》
        </Link>
      </div>
    </div>
  );
};
