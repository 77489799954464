import Dialog from "../general/Dialog";
import {useState} from "react";
import {useLockFn, useMount, useUpdate} from "ahooks";
import axios from "../../axios";
import {Link} from "react-router-dom";
import general from "../../general";
import Empty from "../general/Empty";

export default () => {
  const update = useUpdate();
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/gift/gamePackList?isvip=2')
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data.gameList);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const receive = useLockFn(async gift => {
    if (gift.is_receive) {
      return;
    }
    Dialog.loading();
    gift.receiving = true;
    update();
    await axios.post('/gift/packReceive', {pack_id: gift.packid})
      .finally(() => {
        Dialog.close();
        gift.receiving = false;
        update();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success('领取成功');
        gift.is_receive = true;
        update();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="gift-vipgifts">
    {datas.length ? <div className="items">
      {datas.map(item => <>
        {item.list.map(item => <Link to={`/welfare/gift?id=${item.packid}`} className="item" onClick={() => general.gift = item}>
          <div className="data">
            <div className="name">
              <div className="named">{item.packname}</div>
              <div className="vip"><img src="/static/img/gift-vip.png" alt=""/></div>
            </div>
            <div className="desc">{item.packcontent}</div>
          </div>
          <div
            className={`operate ${item.receiving ? 'receiving' : ''} ${item.is_receive ? 'received' : ''}`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              receive(item);
            }}/>
        </Link>)}
      </>)}
    </div> : null}

    {!datas.length && !loading ? <Empty/> : null}
  </div>;
}