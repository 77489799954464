import { useContext, useEffect, useState } from "react";
import ActiveContext from "./ActiveContext";
import { useMount, useUnmount, useUpdateEffect } from "ahooks";
import general from "../../general";

const datas = [];

const useActiveState = (name) => {
  const [state, setState] = useState(general[name]);

  const activeSetState = (state) => {
    setState((old) => ({ ...old, ...state }));
    if (typeof state === "string") {
      general[name] = state;
    } else {
      general[name] = { ...general[name], ...state };
    }
    let setter = "set" + name.substring(0, 1).toUpperCase() + name.substring(1);
    if (general[setter]) {
      general[setter]();
    }
  };

  useMount(() => {
    datas.push({ name, state, setState, activeSetState });
  });

  useUnmount(() => {
    let index = datas.findIndex((item) => item.setState === setState);
    if (index !== -1) {
      datas.splice(index, 1);
    }
  });

  const active = useContext(ActiveContext);
  useEffect(() => {
    if (active) {
      setState(general[name]);
    }
    let index = datas.findIndex((data) => data.setState === setState);
    if (index !== -1) {
      datas[index].active = active;
    }
  }, [active]);

  useUpdateEffect(() => {
    datas.forEach((data) => {
      if (data.active && data.name === name && data.setState !== setState) {
        data.setState(state);
      }
    });
  }, [state]);

  return [state, activeSetState];
};

export default useActiveState;
