import { Swiper, SwiperSlide } from "swiper/react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "swiper/swiper-bundle.css";
import { useState } from "react";
import { useMount, useThrottleEffect, useThrottleFn } from "ahooks";
import Dialog from "./Dialog";
import axios from "../../axios";

let support = !/android 5.\d+(.\d+)*/i.test(navigator.userAgent);

// 游戏活动公告
export default ({ close }) => {
  const [datas, setDatas] = useState([]);
  const [current, setCurrent] = useState(0);
  const [notify, setNotify] = useState(false);
  const { run: load } = useThrottleFn(async () => {
    Dialog.loading();
    return axios
      .get(`/game/gameActivityList`)
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        setDatas(resData.data.list);
      })
      .catch((error) => Dialog.error(error.message));
  });
  useMount(() => {
    if (!support) {
      Dialog.alert(
        "提示",
        "无法显示游戏活动公告",
        () => (window.location.href = "sdk://close")
      );
    } else {
      load();
    }
  });
  useThrottleEffect(() => {
    if (notify === undefined || !datas.length) {
      return;
    }
    axios
      .post(`/game/setActivityStatus`, { state: notify })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          console.error(resData);
        }
      })
      .catch(console.error);
  }, [notify, datas]);

  return !support ? null : (
    <div className="tips-index">
      <div className="container">
        <div className="head">
          <div className="title">游戏活动公告</div>
          <CloseOutlined
            className="close"
            onClick={() =>
              close ? close() : (window.location.href = "sdk://close")
            }
          />
        </div>
        <div className="body">
          <Swiper autoHeight onSlideChange={(e) => setCurrent(e.activeIndex)}>
            {datas.map((data) => (
              <SwiperSlide className="body-container">
                <div className="title">{data.title}</div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="foot">
          <div className="items">
            {datas.map((item, index) => (
              <div className={`item ${index == current ? "active" : ""}`} />
            ))}
          </div>
          <div className="ctrl">
            <div
              className={`input ${notify ? "active" : ""}`}
              onClick={() => setNotify(!notify)}
            >
              <CheckOutlined className="icon" />
            </div>
            <div className="text">今日不再提醒</div>
          </div>
        </div>
      </div>
    </div>
  );
};
