import useActiveState from "../general/useActiveState";
import {useState} from "react";
import {useInterval, useLockFn} from "ahooks";
import axios from "../../axios";
import Dialog from "../general/Dialog";
import {useHistory} from "react-router-dom";

export default () => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');

  const [verify, setVerify] = useState(false);

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = type => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (type == 'unbind_mobile' && data.mobile != user.mobile) {
      Dialog.error('手机号码不正确');
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    axios.post('/sms/getSmsCode', {mobile: data.mobile, type})
      .finally(() => {
        setCoding(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setCodeTime(60);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const [data, setData] = useState({});
  const next = useLockFn(async () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    Dialog.loading();
    await axios.post('/user/unbindMobile', {mobile: user.mobile, code: data.code})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setVerify(false);
        setUser({...user, mobile: ''});
        setCodeTime(0);
        setData({mobile: '', code: ''});
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  const save = useLockFn(async () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    Dialog.loading();
    await axios.post('/user/bindMobile', {mobile: data.mobile, code: data.code})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success('操作成功', () => {
          setUser({...user, mobile: data.mobile})
        });
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="user-phone">
    {!user.mobile || data.password_token ? <div className="phone-phone">
      <div className="title">您正在为<span>{user.username}</span>绑定手机</div>
      <div className="input">
        <input type="text" placeholder="请输入手机号" onChange={e => setData({...data, mobile: e.target.value})}/>
      </div>
      <div className="input">
        <input type="text" placeholder="请输入验证码" onChange={e => setData({...data, code: e.target.value})}/>
        <div className={'codebtn' + (coding || codeTime ? ' disable' : '')} onClick={() => code('bind_mobile')}>
          {coding ? '获取中' : (codeTime ? (codeTime + '秒后重试') : '获取验证码')}
        </div>
      </div>
      <div className="operate" onClick={save}>提交</div>
    </div> : null}
    {user.mobile && !verify ? <div className="phone-index">
      <img src="/static/img/phone-cover.png" alt=""/>
      <p>绑定手机号：{user.mobile?.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')}</p>
      <span onClick={() => {
        setVerify(true);
        setData({mobile: '', code: ''});
      }}>换绑手机</span>
    </div> : null}
    {verify ? <div className="phone-verify">
      <div className="tips1">
        您当前绑定的手机号为：
        <span>{user.mobile?.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')}</span>
      </div>
      <div className="tips2">为了您的账号安全，需验证已绑定手机</div>
      <div className="input">
        <input type="text" placeholder="请输入手机号" onChange={e => setData({...data, mobile: e.target.value})}/>
      </div>
      <div className="input">
        <input type="text" placeholder="请输入验证码" onChange={e => setData({...data, code: e.target.value})}/>
        <div className={'codebtn' + (coding || codeTime ? ' disable' : '')} onClick={() => code('unbind_mobile')}>
          {coding ? '获取中' : (codeTime ? (codeTime + '秒后重试') : '获取验证码')}
        </div>
      </div>
      <div className="operate" onClick={next}>下一步</div>
    </div> : null}
  </div>;
}
