const app = JSON.parse(localStorage.getItem("app")) || {
  osType: "",
  osInfo: "",
  imei: "",
  udid: "",
  sdkVersion: "",
  agent: "",
  appId: "",
  gameId: "",
};
app.altUsername = undefined;
export default {
  native: true,
  app: app,
  setApp() {
    let app = { ...this.app };
    app.altUsername = undefined;
    localStorage.setItem("app", JSON.stringify(app));
  },
  user: {},
};
