import Dialog from "./Dialog";
import useActiveState from "./useActiveState";

export const hasBoxApp = async () => {
  return new Promise((resolve, reject) => {
    try {
      if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        window.checkAPPIsExist = resolve;
        window.webkit.messageHandlers.hasApp.postMessage({scheme: 'box9917://', func: 'checkAPPIsExist'});
        setTimeout(() => reject(new Error('timeout')), 1527);
      } else {
        for (const packname of ['box9917://', 'com.aoyou.gamebox', 'com.aoyou.btwan', 'com.zhangjian.haiwan']) {
          if (window.aoyou.hasApp(packname)) {
            resolve(true);
            break;
          }
        }
        resolve(false);
      }
    } catch (e) {
      console.error(e);
      // reject(e);
      resolve(false);
    }
  });
}

export default ({scheme = 'box9917://', url, className = null, children = null, success = null, error = null, onClick}) => {

  const [app] = useActiveState('app');

  if (!success) {
    success = () => {
      console.log('success');
    }
  }
  if (!error) {
    error = () => {
      Dialog.alert('提示', '还未安装游戏盒子，立即下载？', () => {
        window.location.href = `sdk://browser?url=${encodeURIComponent(url || `https://app.9917.cn?a=${app.agent}&brand=${app.brand}`)}`;
      });
    }
  }

  const click = async () => {
    try {
      if (await hasBoxApp()) {
        window.location.href = scheme;
        success();
      } else {
        error();
      }
    } catch (e) {
      Dialog.alert('error', `${e.message}`);
    }
  }

  return <div className={className} onClick={onClick || click}>
    {children}
  </div>;
};
