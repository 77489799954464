import {
  CustomerServiceOutlined,
  GiftOutlined,
  InteractionOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Account from "./view/account/Account";
import PhoneLogin from "./view/account/PhoneLogin";
import PasswordLogin from "./view/account/PasswordLogin";
import Register from "./view/account/Register";
import Alt from "./view/alt/Alt";
import { AltAlt } from "./view/alt/Alt";
import UserIndex from "./view/user/Index";
import Welfare from "./view/welfare/Welfare";
import Server from "./view/server/Server";
import Trades from "./view/trade/Trades";
import Service from "./view/service/Service";
import Vouchers from "./view/voucher/Vouchers";
import Rebates from "./view/rebate/Rebates";
import Gifts from "./view/gift/Gifts";
import VipGifts from "./view/gift/VipGifts";
import UserAlt from "./view/user/Alt";
import UserVouchers from "./view/user/Vouchers";
import UserOrders from "./view/user/Orders";
import UserGifts from "./view/user/Gifts";
import UserRebates from "./view/user/Rebates";
import UserRebate from "./view/user/Rebate";
import UserPassword from "./view/user/Password";
import UserPhone from "./view/user/Phone";
import UserAntiAddiction from "./view/user/AntiAddiction";
import UserMsgs from "./view/user/Msgs";
import UserMsg from "./view/user/Msg";
import Voucher from "./view/voucher/Voucher";
import Rebate from "./view/rebate/Rebate";
import Gift from "./view/gift/Gift";
import RealName from "./view/general/RealName";
import { Explain as RealNameExplain } from "./view/general/RealName";
import Forget from "./view/account/Forget";
import Agreement from "./view/account/Agreement";
import ForgetFind from "./view/account/ForgetFind";
import ForgetSet from "./view/account/ForgetSet";

const route = {
  side: [
    {
      id: "user",
      name: "用户",
      title: "个人中心",
      path: "/user",
      icon: <UserOutlined />,
      view: UserIndex,
    },
    {
      id: "welfare",
      name: "福利",
      path: "/welfare",
      icon: <GiftOutlined />,
      view: Welfare,
      views: [
        {
          id: "voucher",
          name: "卡券",
          path: "/welfare/vouchers",
          view: Vouchers,
        },
        {
          id: "rebate",
          name: "福利",
          path: "/welfare/rebates",
          view: Rebates,
        },
        {
          id: "pack",
          name: "礼包",
          path: "/welfare/gifts",
          view: Gifts,
        },
        {
          id: "vip",
          name: "VIP专属",
          path: "/welfare/vipgifts",
          view: VipGifts,
        },
      ],
    },
    {
      id: "server",
      name: "开服",
      title: "开服",
      path: "/server",
      icon: <UnorderedListOutlined />,
      view: Server,
    },
    {
      id: "trades",
      name: "交易",
      title: "交易",
      path: "/trades",
      icon: <InteractionOutlined />,
      view: Trades,
    },
    {
      id: "service",
      name: "客服",
      title: "客服",
      path: "/service",
      icon: <CustomerServiceOutlined />,
      view: Service,
    },
  ],
  navigation: [
    {
      id: "login",
      path: /login|phonelogin|passwordlogin|register/,
      head: false,
      view: Account,
      views: [
        {
          id: "phone-login",
          path: "/phonelogin",
          name: "手机登录",
          view: PhoneLogin,
        },
        {
          id: "password-login",
          path: "/passwordlogin",
          name: "账号登录",
          view: PasswordLogin,
        },
        {
          id: "register",
          path: "/register",
          name: "快速注册",
          view: Register,
        },
      ],
    },
    {
      id: "forget",
      title: "忘记密码",
      path: "/forget",
      view: Forget,
    },
    {
      id: "forget-find",
      title: "忘记密码",
      path: "/forget-find",
      view: ForgetFind,
    },
    {
      id: "forget-set",
      title: "忘记密码",
      path: "/forget-set",
      view: ForgetSet,
    },
    {
      id: "agreement",
      title: "用户注册协议",
      path: "/agreement",
      view: Agreement,
    },
    {
      id: "alt",
      path: "/alt",
      view: Alt,
    },
    {
      path: "/alt/alt",
      view: AltAlt,
    },
    {
      title: "防沉迷系统",
      path: "/realname",
      view: RealName,
    },
    {
      title: "防沉迷系统说明",
      path: "/realname/explain",
      view: RealNameExplain,
    },
    {
      title: "我的小号",
      path: "/user/alt",
      view: UserAlt,
    },
    {
      title: "我的代金券",
      path: "/user/vouchers",
      view: UserVouchers,
    },
    {
      title: "订单记录",
      path: "/user/orders",
      view: UserOrders,
    },
    {
      title: "我的礼包",
      path: "/user/gifts",
      view: UserGifts,
    },
    {
      title: "福利申请记录",
      path: "/user/rebates",
      view: UserRebates,
    },
    {
      title: "福利申请记录",
      path: "/user/rebate",
      view: UserRebate,
    },
    {
      title: "修改密码",
      path: "/user/password",
      view: UserPassword,
    },
    {
      title: "绑定手机",
      path: "/user/phone",
      view: UserPhone,
    },
    {
      title: "防沉迷系统",
      path: "/user/anti-addiction",
      view: UserAntiAddiction,
    },
    {
      title: "消息",
      path: "/user/msgs",
      view: UserMsgs,
    },
    {
      path: "/user/msg",
      view: UserMsg,
    },
    {
      title: "代金券详情",
      path: "/welfare/voucher",
      view: Voucher,
    },
    {
      title: "福利详情",
      path: "/welfare/rebate",
      view: Rebate,
    },
    {
      title: "礼包详情",
      path: "/welfare/gift",
      view: Gift,
    },
  ],
};

export default route;
