import Dialog from "../general/Dialog";
import {useState} from "react";
import {useLockFn, useMount} from "ahooks";
import axios from "../../axios";
import {Link} from "react-router-dom";
import general from "../../general";
import Image from "../general/Image";
import Empty from "../general/Empty";

export default () => {
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/activityRebate/rebateList')
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data.list);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return <div className="user-rebates">
    {datas.length > 0 ? <div className="items">
      {datas.map(item => <Link to={`/user/rebate?id=${item.id}`} className="item" onClick={() => general.rebate = item}>
        <div className="body">
          <div className="icon"><Image src={item.game_icon} alt=""/></div>
          <div className="data">
            <div className="name">{item.game_name}</div>
            <div className="desc">{item.game_name}</div>
          </div>
        </div>
        <div className="foot">
          <div className={`state state-${item.status}`}>订单状态：</div>
          <div className="opt">详情</div>
        </div>
      </Link>)}
    </div> : null}
    {datas.length == 0 && !loading ? <Empty/> : null}
  </div>;
}