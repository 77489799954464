import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Fragment, useState } from "react";
import useActiveState from "./useActiveState";
import { useMount, useRequest, useThrottleEffect } from "ahooks";
import axios from "../../axios";
import Dialog from "./Dialog";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { openBoxApp2 } from "./Old";
import SchemeLink from "./SchemeLink";
import { useHistory } from "react-router-dom";
import $ from "jquery";

let received;

const Real = ({ close }) => {
  const [app] = useActiveState("app");

  const sb250 = app.branch != "old" && app.osType != "ios";
  const MaybeCopyToClipboard = sb250 ? CopyToClipboard : Fragment;

  const [cdk, setCdk] = useState(false);
  const [remind, setRemind] = useState(false);
  const [notify, setNotify] = useState(false);
  const history = useHistory();

  const { data = {}, mutate } = useRequest(async () => {
    return axios
      .get("/newuser/getData")
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        return resData.data;
      })
      .catch((error) => Dialog.error(error.message));
  });

  const { loading: rewarding, run: reward } = useRequest(
    async () => {
      if (data.reward || rewarding) {
        return;
      }
      log("voucher");
      return axios
        .post("/newuser/reward")
        .then(({ data: resData }) => {
          if (resData.code != 0) {
            Dialog.error(resData.msg);
            return;
          }
          received = true;
          mutate((data) => ({ ...data, reward: true }));
          Dialog.success("领取成功，游戏内充值可直接使用");
          return true;
        })
        .catch((err) => Dialog.error(err.message));
    },
    { manual: true }
  );

  const { loading: receiving, run: receive } = useRequest(
    async () => {
      if (data.gift_received || receiving) {
        return;
      }
      log("pack");
      return axios
        .post("/newuser/receivePack", { id: data.gift_id })
        .then(({ data: resData }) => {
          if (resData.code != 0) {
            Dialog.error(resData.msg);
            return;
          }
          received = true;
          setCdk(true);
          mutate((data) => ({
            ...data,
            gift_received: true,
            gift_cdk: resData.data.cdk,
          }));
          return true;
        })
        .catch((err) => Dialog.error(err.message));
    },
    { manual: true }
  );

  const closeRemind = (type) => {
    if (type == "again") {
      if (notify == true) {
        setNotify(!notify);
      }
    } else {
      if (app.branch === "old") {
        window.history.back();
      } else {
        close();
      }
    }
    setRemind(false);
  };

  const closeBox = () => {
    if (received) {
      if (app.branch === "old") {
        window.history.back();
      } else {
        close();
      }
      return;
    }
    setRemind(true);
  };

  const log = (action, param) =>
    axios
      .get("/newuser/log", { params: { action, param } })
      .catch((err) => console.error(err.message));

  useMount(() => {
    log("enter");
    const flex = $(".flex");
    setTimeout(() => {
      flex.animate({ scrollTop: flex[0].offsetHeight || 0 }, 1000, null, () => {
        flex.animate({ scrollTop: 0 }, 1000);
      });
    }, 250);
  });

  useThrottleEffect(() => {
    if (notify === undefined) {
      return;
    }
    axios
      .post(`/newuser/setActivityStatus`, { state: notify })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          console.error(resData);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [notify]);

  return (
    <>
      <div className="newuser-newuser">
        <div className="container">
          <div className="title">
            新人福利
            {/* {document.documentElement.clientWidth} x {document.documentElement.clientHeight}*/}
            <div className="close">
              <CloseCircleOutlined
                className="icon"
                onClick={() => {
                  log("close");
                  closeBox();
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="part-one">
              <div className="voucher-title">
                <img src="/static/img/newuser/voucher-title.png" alt="" />
              </div>
              <div className="voucher">
                <div
                  className="item"
                  style={{
                    backgroundImage:
                      "url(/static/img/newuser/voucher.png?version=0103)",
                  }}
                >
                  <div className="amount">
                    <span className="num">2</span>元
                  </div>
                  <div className="meet">满6元可用</div>
                </div>
                <div
                  className="item"
                  style={{
                    backgroundImage:
                      "url(/static/img/newuser/voucher.png?version=0103)",
                  }}
                >
                  <div className="amount">
                    <span className="num">3</span>元
                  </div>
                  <div className="meet">满10元可用</div>
                </div>
                <div
                  className="item"
                  style={{
                    backgroundImage:
                      "url(/static/img/newuser/voucher.png?version=0103)",
                  }}
                >
                  <div className="amount">
                    <span className="num">6</span>元
                  </div>
                  <div className="meet">满20元可用</div>
                </div>
              </div>
              <div
                className={`opt ${rewarding ? "ing" : ""} ${
                  data.reward ? "disable" : ""
                }`}
                onClick={reward}
              />
              <div className="tip">仅限BT游戏使用</div>
            </div>

            {data.pack ? (
              <div className="part-two">
                <div className="pack-title">
                  <img src="/static/img/newuser/648-title.png" alt="" />
                </div>
                <div className="pack-info">
                  <div className="pack">
                    <div className="icon">
                      <img src={data.icon} alt={data.name} />
                    </div>
                    <div className="title">
                      <div className="main-title">{data.name}</div>
                      <div className="sub-title">{data.branch}</div>
                      <div className="type">{data.type}</div>
                    </div>
                    <div
                      className={`opt ${receiving ? "ing" : ""} ${
                        data.gift_received ? "disable" : ""
                      }`}
                      onClick={receive}
                    />
                  </div>
                  <div className="recharge-title">
                    <span>648充值卡</span>
                    <img src="/static/img/newuser/recharge-title.png" alt="" />
                  </div>
                  <div className="recharge-content">
                    <span>{data.gift_content}</span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <SchemeLink
          scheme={`box9917://`}
          onClick={
            app.branch === "old"
              ? openBoxApp2
              : app.osType === "ios"
              ? () =>
                  (window.location.href = `sdk://browser?url=${encodeURIComponent(
                    `https://app.9917.cn?a=${app.agent}&brand=${app.brand}&direct=1`
                  )}`)
              : null
          }
          error={() => {
            Dialog.alert(
              "提示",
              "还未安装游戏盒子，立即下载？",
              sb250 ? () => {} : undefined,
              {
                btn: sb250 ? (
                  <>
                    <CopyToClipboard
                      text={`https://app.9917.cn?a=${app.agent}&brand=${app.brand}`}
                      onCopy={() =>
                        Dialog.info(
                          <>
                            已复制链接
                            <br />
                            请在浏览器中打开
                          </>
                        )
                      }
                    >
                      <div>复制链接</div>
                    </CopyToClipboard>
                  </>
                ) : undefined,
              }
            );
          }}
        >
          <div className="download-box" onClick={() => log("app")}>
            <div className="download-info">
              <div className="logo">
                <img src="/static/img/newuser/logo.png" alt="" />
              </div>
              <div className="info">
                <div className="title">9917游戏</div>
                <div className="tip">更多游戏、更多福利尽在9917游戏盒子</div>
                <div className="tag">
                  <span className="icon">BT游戏</span>
                  <span className="icon">GM特权</span>
                  <span className="icon">648充值</span>
                </div>
              </div>
              <div className="download-btn">官方下载</div>
            </div>
          </div>
        </SchemeLink>
        {cdk ? (
          <div className="pack-receive">
            <div className="body">
              <div className="title">领取成功</div>
              <MaybeCopyToClipboard
                text={data.gift_cdk}
                onCopy={() =>
                  Dialog.info(
                    <>
                      已复制礼包码
                      <br />
                      请在游戏中兑换
                    </>
                  )
                }
              >
                <div className="content">
                  <div className="cdk">礼包码：{data.gift_cdk}</div>
                  <div className="btn">复制</div>
                </div>
              </MaybeCopyToClipboard>
              <div className="use">
                <p>1、游戏中点击个人头像兑换使用</p>
                <p>
                  2、领取记录：在“游戏个人中心—&gt;用户—&gt;礼包领取记录—&gt;648礼包”下可查看
                </p>
              </div>
              <div className="confirm" onClick={() => setCdk(false)}>
                我知道了
              </div>
            </div>
          </div>
        ) : null}
        {remind ? (
          <div className="remind">
            <div className="body">
              <div className="title">提醒</div>
              <div className="content">
                <div className="message">您确定放弃新人专享福利吗？</div>
                <div className="option">
                  <div
                    className={`input ${notify ? "active" : ""}`}
                    onClick={() => setNotify(!notify)}
                  >
                    <CheckOutlined className="icon" />
                  </div>
                  <div className="not-remind">不再弹新人福利</div>
                </div>
              </div>
              <div className="btn">
                <div className="again" onClick={() => closeRemind("again")}>
                  再想想
                </div>
                <div className="line"></div>
                <div className="sure" onClick={() => closeRemind("sure")}>
                  确定放弃
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default () => {
  const [app] = useActiveState("app");
  const [state, setState] = useState(true);

  return state ? (
    <Real
      close={() => {
        if (app.branch === "old") {
          window.history.back();
        } else {
          setState(false);
        }
      }}
    />
  ) : null;
};
