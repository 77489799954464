import useActiveState from "../general/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";

export default () => {
  const app = useActiveState("app")[0];
  const [params] = useUrlState();

  return (
    <div className="account-agreement">
      <iframe src={params.url} />
    </div>
  );
};
