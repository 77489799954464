import {useHistory, useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import route from "../../route";
import Active from "./Active";
import URI from "urijs";
import ActiveContext from "./ActiveContext";
import {LeftOutlined} from "@ant-design/icons";

const Navigation = () => {
  const history = useHistory();
  const {router, head, title, operate} = useContext(ActiveContext);

  return <>
    {router.head === false ? null : <div className="center-head">
      {head ? head : <>
        <div className="back" onClick={() => {
          // history.goBack();
          history.push('/alt');
        }}><LeftOutlined className="icon"/></div>
        <div className="title">{title || router.title}</div>
        <div className="operate">{operate}</div>
      </>}
    </div>}
    <div className={`center-body ${router.head === false ? 'nohead' : ''}`}>
      <router.view router={router}/>
    </div>
  </>;
}

export default () => {
  const history = useHistory();
  const location = useLocation();

  const [views, setViews] = useState([]);
  useEffect(() => {
    let uri = URI(window.location.href);
    setViews(_views => {
      let views = [..._views];
      let url = uri.pathname() + uri.search();
      let pathname = uri.pathname();
      let router = route.navigation.find(item => {
        if (typeof item.path != 'string') {
          return item.path.test(pathname);
        } else {
          return item.path === pathname;
        }
      });
      if (router) {
        views.reverse();
        let index = views.findIndex(item => item.multi ? item.url === url : item.view === router.view);
        views.reverse();
        if (index > 0) {
          while (true) {
            let item = views[views.length - 1];
            if (item.multi ? item.url !== url : item.view !== router.view) {
              views.pop();
            } else {
              break;
            }
          }
        }
        if (index === -1) {
          views.push(router);
        }
      } else {
        views = [];
      }
      // console.log([...views]);
      return [...views];
    });
  }, [history, location]);

  return <>
    {views.map((item, index) => <Active
      className={`center-container ${item.id}`}
      active={index + 1 === views.length}
      router={item}>
      <Navigation/>
    </Active>)}
  </>
};
