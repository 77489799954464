import {useLockFn, useSetState} from "ahooks";
import {Link, useHistory} from "react-router-dom";
import Dialog from "./Dialog";
import axios from "../../axios";

export const Explain = () => {
  return <div className="realname-explain">
    <h1>未成年玩家受到以下游戏限制：</h1>
    <h2>游戏时段时长</h2>
    <p>每日22:00至次日8:00将被限制</p>
    <p>法定节假日每日累计游戏时间不得超过3小时</p>
    <p>其他时间每日累计游戏时间不得超过1.5小时</p>
    <h1>游戏充值</h1>
    <p>8周岁以下用户将不提供游戏付费服务</p>
    <p>8周岁以上未满16周岁用户，单次充值不得超 过50元人民币；每日累计充值不得超过200元人民币</p>
    <p>16周岁以上未满18周岁用户，单次充值不得超过 100人民币，每月累计充值不得超过400元人民币</p>
  </div>;
}

export default () => {
  const history = useHistory();
  const [data, setData] = useSetState({});
  const save = useLockFn(async () => {
    if (!data.name) {
      Dialog.error('请输入真实姓名');
      return;
    }
    if (!data.number) {
      Dialog.error('请输入身份证');
      return;
    }
    Dialog.loading();
    await axios.post('/user/authentication', data)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success('操作成功');
        history.goBack();
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="realname-index">
    <div className="title">
      根据国家相关规定，请完成实名认证；未成年玩家将无法登录游戏。
    </div>
    {/*<div className="info">
      <div className="text">
        <p>1、某些游戏时间段将受到限制</p>
        <p>2、游戏充值金额将受到限制</p>
      </div>
      <Link to="/realname/explain" className="more">查看详情 ></Link>
    </div>*/}
    <div className="input">
      <input type="text" placeholder="请输入真实姓名" onChange={e => setData({name: e.target.value})}/>
    </div>
    <div className="input">
      <input type="text" placeholder="请输入身份证" onChange={e => setData({number: e.target.value})}/>
    </div>
    <div className="operate" onClick={save}>立即认证</div>
  </div>;
}