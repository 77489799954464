import Dialog from "../general/Dialog";
import {useEffect, useState} from "react";
import {useLockFn, useMount, useUpdate} from "ahooks";
import axios from "../../axios";
import {Link} from "react-router-dom";
import general from "../../general";
import Empty from "../general/Empty";
import SchemeLink from "../general/SchemeLink";
import {useRecoilState} from "recoil";
import badgeState from "../general/badgeState";
import {BellOutlined} from "@ant-design/icons";
import {openBoxApp2} from "../general/Old";
import useActiveState from "../general/useActiveState";

export default () => {
  const [app] = useActiveState('app');
  const [badge, setBadge] = useRecoilState(badgeState);
  const update = useUpdate();
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios.get('/coupon/gameCouponList')
        .finally(() => {
          Dialog.close();
          setLoading(false);
        })
        .then(res => {
          if (res.data.code != 0) {
            Dialog.error(res.data.msg);
            return;
          }
          setDatas(res.data.data.couponList);
          setBadge(badge => ({...badge, voucher: 0}));
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  useMount(load);

  const receive = useLockFn(async voucher => {
    if (voucher.status == 2 || voucher.surplus_total < 1) {
      return;
    }
    Dialog.loading();
    voucher.receiving = true;
    update();
    await axios.post('/coupon/gameCouponReceive', {id: voucher.id})
        .finally(() => {
          Dialog.close();
          voucher.receiving = false;
          update();
        })
        .then(res => {
          if (res.data.code != 0) {
            Dialog.error(res.data.msg);
            return;
          }
          Dialog.success('领取成功');
          voucher.status = 2;
          update();
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  return <div className={`voucher-vouchers ${datas.length == 0 && !loading ? 'empty' : ''}`}>
    <div className="body">
      <SchemeLink className="monthcard" scheme={'box9917://webview?url=https://sys.9917.cn/home/MonthCard'} onClick={app.branch === 'old' ? openBoxApp2 : null}>
        <div>
          <h1>开通<span>省钱卡</span></h1>
          <p>最高可领取200元</p>
        </div>
        <span>马上开通</span>
      </SchemeLink>
      {datas.map(item => <Link to={`/welfare/voucher?id=${item.id}`} className="item" onClick={() => general.voucher = item}>
        <div className="amount">
          <div className="value">
            <div className="unit">￥</div>
            <div className="number">{item.amount * 1}</div>
          </div>
          <div className="desc">
            {item.meet_amount > 0 ? `满${item.meet_amount * 1}使用` : '任意金额使用'}
          </div>
        </div>
        <div className="info">
          <div className="data">
            <div className="name">
              <div className="type">单游券</div>
              <div className="named">{item.name}</div>
            </div>
            <div className="desc">{item.end_time}</div>
          </div>
          <div
            className={`operate ${item.surplus_total < 1 ? 'nothing' : ''} ${item.receiving ? 'receiving' : ''} ${item.status == 2 ? 'received' : ''}`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              receive(item);
            }}/>
        </div>
      </Link>)}
    </div>
    {datas.length == 0 && !loading ? <Empty/> : null}
  </div>;
}
