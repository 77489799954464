import axios from "axios";
import Qs from "qs";
import config from "./config";
import dayjs from "dayjs";
import md5 from "blueimp-md5";
import chance from "chance";
import general from "./general";

const createAxios = axios.create({ baseURL: config.url });

createAxios.interceptors.request.use(
  (config) => {
    let app = general.app;

    config.paramsSerializer = (params) =>
      Qs.stringify(params, { arrayFormat: "indices" });

    config.headers["timestamp"] = dayjs().unix();
    config.headers["noncestr"] = chance().string({
      pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      min: 12,
      max: 12,
    });
    let tmp =
      config.headers["timestamp"] + config.headers["noncestr"] + "Aoyou9917";
    let sign = [];
    while (tmp.length > 0) {
      sign.push(tmp.slice(0, 1));
      tmp = tmp.slice(1);
    }
    sign.sort();
    config.headers["sign"] = md5(sign.join(""));

    config.headers["device"] = app.osType == "ios" ? "3" : "2";
    config.headers["deviceinfo"] = app.osInfo;
    config.headers["imeil"] = app.imei;
    config.headers["udid"] = app.udid || "";
    config.headers["sdk-version"] = app.sdkVersion;
    config.headers["agent"] = app.agent;
    config.headers["appid"] = app.appId;
    config.headers["game-id"] = app.gameId;
    config.headers["server-id"] = app.serverId || "";
    config.headers["role-id"] = app.roleId || "";
    config.headers["branch"] = app.branch || "";
    config.headers["brand"] = app.brand;
    config.headers["agreement"] = "new";

    if (app.username && app.token) {
      config.headers["username"] = app.username;
      config.headers["token"] = app.token;
    }

    if (app.altUsername) {
      config.headers["xh-username"] = app.altUsername;
    }

    if (app.source) {
      config.headers["source"] = app.source;
    }

    // config.headers['debug'] = 'captcha';
    // config.headers['idfv'] = '123456';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default createAxios;
