import "../css/css.css";
import { useMount } from "ahooks";
import useUrlState from "@ahooksjs/use-url-state";
import Tips from "./general/Tips";
import Side from "./general/Side";
import Pay from "./pay/Pay";
import Center from "./general/Center";
import useActiveState from "./general/useActiveState";
import Captcha from "./general/Captcha";
import Old from "./general/Old";
import axios from "../axios";
import Offline from "./general/Offline";
import Dialog from "./general/Dialog";
import React from "react";
import Debug from "./general/Debug";
import Newuser from "./general/Newuser";

const Route = () => {
  const [app] = useActiveState("app");

  if (app.branch !== "h5") {
    if (location.pathname.startsWith("/offline")) {
      return <Offline />;
    }
    if (location.pathname.startsWith("/newuser")) {
      return <Newuser />;
    }
    if (location.pathname.startsWith("/tips")) {
      return <Tips />;
    }

    if (
      location.pathname.startsWith("/user") ||
      location.pathname.startsWith("/welfare") ||
      location.pathname.startsWith("/server") ||
      location.pathname.startsWith("/trade") ||
      location.pathname.startsWith("/service")
    ) {
      return <Side />;
    }

    if (location.pathname.startsWith("/pay")) {
      return <Pay />;
    }

    if (location.pathname.startsWith("/captcha")) {
      return <Captcha />;
    }
  }

  return <Center />;
};

export default () => {
  const [params] = useUrlState();
  let [app, setApp] = useActiveState("app");
  const setUser = useActiveState("user")[1];
  useMount(() => {
    setApp((app = params));
    if (params.username) {
      setUser({ username: params.username });
    }
    if (!app.brand) {
      axios
        .get("/app/appinfo")
        .then(async ({ data: resData }) => {
          if (resData.code != 0) {
            Dialog.error(resData);
            return;
          }
          setApp((app = { ...app, ...resData.data }));
          // console.log(app);
        })
        .catch((err) => Dialog.error(err.message));
    }
    // console.log(app);
  });

  return (
    <div className={`root root-${app.branch}`}>
      {app.branch === "old" ? <Old /> : null}
      <Route />
      <Debug />
    </div>
  );
};
