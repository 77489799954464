import { CloseCircleOutlined } from "@ant-design/icons";
import React, { Fragment, useEffect, useState } from "react";
import useActiveState from "./useActiveState";
import { useLockFn, useMount, useRequest, useUpdate } from "ahooks";
import axios from "../../axios";
import Dialog from "./Dialog";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import _ from "lodash";
import { openBoxApp2, openUrl } from "./Old";
import SchemeLink from "./SchemeLink";
import useReactive from "./useReactive";
import qs from "qs";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

SwiperCore.use([Pagination]);

const Real = ({ close }) => {
  const update = useUpdate();

  const [app] = useActiveState("app");

  const sb250 = app.branch != "old" && app.osType != "ios";
  const MaybeCopyToClipboard = sb250 ? CopyToClipboard : Fragment;

  const { data = {}, mutate } = useRequest(async () => {
    return axios
      .get("/offline/getData", { params: { offline: app.offline ? 1 : 0 } })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        return resData.data;
      })
      .catch((error) => Dialog.error(error.message));
  });

  const { loading: rewarding, run: reward } = useRequest(
    async () => {
      if (data.reward || rewarding) {
        return;
      }
      log("voucher");
      return axios
        .post("/offline/reward")
        .then(({ data: resData }) => {
          if (resData.code != 0) {
            Dialog.error(resData.msg);
            return;
          }
          mutate((data) => ({ ...data, reward: true }));
          Dialog.success("领取成功，游戏内充值可直接使用");
          return true;
        })
        .catch((error) => Dialog.error(error.message));
    },
    { manual: true }
  );

  const open = (url) => {
    if (app.branch === "old") {
      openUrl(url);
    } else {
      window.location.href = `sdk://browser?url=${encodeURIComponent(url)}`;
    }
  };

  const openGame = (game, _return) => {
    let url = "";
    if (app.osType == "ios") {
      url = `https://iosd.9917.cn/${game.id}/${app.agent}?${qs.stringify({
        udid: app.udid,
      })}`;
    } else {
      url = `https://and.9917.cn/${game.id}/${app.agent}`;
    }
    if (_return) {
      return url;
    }
    log("game", game.id);
    open(url);
  };

  const parms = useReactive({
    wxUrl: "https://wpa1.qq.com/bpZ8LHWZ?_type=wpa&qidian=true",
    wxUrlLoad: false,
    wxUrlClick: false,
  });
  useMount(() => {
    axios
      .get("/getWxServiceUrl")
      .then(({ data: resData }) => {
        if (resData?.data?.url) {
          parms.wxUrl = resData.data.url;
        }
      })
      .finally(() => {
        parms.wxUrlLoad = true;
        if (parms.wxUrlClick) {
          openWx();
        }
      });
  });
  useEffect(() => {
    if (!parms.wxUrlLoad && parms.wxUrlClick) {
      parms.dialog = Dialog.loading("正在拉起微信 请等待");
    } else if (parms.dialog) {
      Dialog.close(parms.dialog);
    }
  }, [parms.wxUrlLoad, parms.wxUrlClick]);
  const openWx = () => {
    log("cs");
    if (app.username === "iosceshi" || app.username === "apkceshi") {
      if (!parms.wxUrlLoad) {
        parms.wxUrlClick = true;
        return;
      }
      axios.post("app/csButtonClick");
      if (parms.wxUrl.startsWith("weixin://")) {
        window.location.href = parms.wxUrl;
        return;
      }
    }
    open(parms.wxUrl);
  };

  const alert = (game) => {
    Dialog.dialog({
      type: "modal",
      mask: true,
      title: "领取成功",
      content: (
        <>
          <p>礼包码：{game.gift_cdk}</p>
          <p>1、游戏中点击个人头像兑换使用</p>
          <p>
            2、领取记录：在“游戏个人中心—&gt;用户—&gt;礼包领取记录—&gt;648礼包”下可查看。
          </p>
        </>
      ),
      btns: [
        {
          text: (
            <>
              <CopyToClipboard
                text={game.gift_cdk}
                onCopy={() => Dialog.info(<>已复制</>)}
              >
                <div>复制礼包码</div>
              </CopyToClipboard>
            </>
          ),
          onPress: () => {},
          style: "success",
        },
      ],
    });
  };

  const receive = useLockFn(async (game) => {
    if (!game.gift_id || game.ing) {
      return;
    }
    if (game.gift_cdk) {
      return alert(game);
    }
    log("pack", game.id);
    game.ing = true;
    update();
    return axios
      .post("/offline/receivePack", { id: game.gift_id })
      .finally(() => {
        game.ing = false;
        update();
      })
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        game.gift_cdk = resData.data.cdk;
        update();
        return alert(game);
      })
      .catch((error) => Dialog.error(error.message));
  });

  const log = (action, param) =>
    axios
      .get("/offline/log", { params: { action, param } })
      .catch((err) => console.error(err.message));

  useMount(() => log("enter"));

  return (
    <>
      <div className="offline-offline">
        <div className="container">
          <div className="title">
            温馨提示
            {/* {document.documentElement.clientWidth} x {document.documentElement.clientHeight}*/}
            <div className="close">
              <div className="text">忽略</div>
              <CloseCircleOutlined
                className="icon"
                onClick={() => {
                  log("close");
                  close();
                }}
              />
            </div>
          </div>
          <div className="tips">
            本款游戏已经关服，为了您更好的体验游戏请选择以下同类型游戏
          </div>
          <div className="flex">
            <div
              className="voucher"
              style={{
                backgroundImage:
                  "url(/static/img/offline/voucher.png?version=0809)",
              }}
              onClick={reward}
            >
              <div className="amount">
                ￥<span>10</span>
              </div>
              <div className="data">
                <div className="name">无门槛游戏充值代金券</div>
                <div className="text">有效期3天</div>
              </div>
              <div
                className={`opt ${rewarding ? "ing" : ""} ${
                  data.reward ? "disable" : ""
                }`}
              />
            </div>
            <div className="game">
              <div className="title">同类型游戏</div>
              <div className="items clearfix">
                <Swiper autoplay={true} pagination={{ clickable: true }}>
                  {app.offline ? (
                    <>
                      {_.chunk(
                        (data.games || []).slice(
                          0,
                          document.documentElement.clientHeight > 333 ? 12 : 6
                        ),
                        document.documentElement.clientHeight > 333 ? 4 : 2
                      ).map((games) => (
                        <SwiperSlide>
                          {games.map((game) => (
                            <>
                              <MaybeCopyToClipboard
                                text={openGame(game, true)}
                                onCopy={() =>
                                  Dialog.info(
                                    <>
                                      已复制游戏链接
                                      <br />
                                      请在浏览器中打开下载
                                    </>
                                  )
                                }
                              >
                                <div
                                  className="item2"
                                  onClick={() => openGame(game)}
                                >
                                  <div className="main">
                                    <div className="icon">
                                      <div className="img">
                                        <img src={game.icon} alt={game.name} />
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="name">
                                        {game.name_main || game.name}
                                      </div>
                                      {!!game.name_branch && (
                                        <div className="desc">
                                          {game.name_branch}
                                        </div>
                                      )}
                                      <div className="tags">{game.tag}</div>
                                    </div>
                                  </div>
                                  <div className="opt">
                                    <div className="btn">去下载</div>
                                    <div
                                      className={`btn ${
                                        game.gift_id ? "" : "no"
                                      } ${game.ing ? "ing" : ""} ${
                                        game.gift_cdk ? "yes" : ""
                                      }`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        receive(game);
                                      }}
                                    />
                                  </div>
                                </div>
                              </MaybeCopyToClipboard>
                            </>
                          ))}
                        </SwiperSlide>
                      ))}
                    </>
                  ) : (
                    <>
                      {_.chunk(
                        data.games || [],
                        document.documentElement.clientHeight > 333 ? 8 : 4
                      ).map((games) => (
                        <SwiperSlide>
                          {games.map((game) => (
                            <>
                              <MaybeCopyToClipboard
                                text={openGame(game, true)}
                                onCopy={() =>
                                  Dialog.info(
                                    <>
                                      已复制游戏链接
                                      <br />
                                      请在浏览器中打开下载
                                    </>
                                  )
                                }
                              >
                                <div
                                  className="item"
                                  onClick={() => openGame(game)}
                                >
                                  <div className="icon">
                                    <div className="img">
                                      <img src={game.icon} alt={game.name} />
                                    </div>
                                  </div>
                                  <div className="name">{game.name}</div>
                                </div>
                              </MaybeCopyToClipboard>
                            </>
                          ))}
                        </SwiperSlide>
                      ))}
                    </>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
          {app.offline ? (
            <>
              <SchemeLink
                className="app"
                scheme={`box9917://`}
                onClick={
                  app.branch === "old"
                    ? openBoxApp2
                    : app.osType === "ios"
                    ? () =>
                        (window.location.href = `sdk://browser?url=${encodeURIComponent(
                          `https://app.9917.cn?a=${app.agent}&brand=${app.brand}&direct=1`
                        )}`)
                    : null
                }
                error={() => {
                  Dialog.alert(
                    "提示",
                    "还未安装游戏盒子，立即下载？",
                    sb250 ? () => {} : undefined,
                    {
                      btn: sb250 ? (
                        <>
                          <CopyToClipboard
                            text={`https://app.9917.cn?a=${app.agent}&brand=${app.brand}`}
                            onCopy={() =>
                              Dialog.info(
                                <>
                                  已复制链接
                                  <br />
                                  请在浏览器中打开
                                </>
                              )
                            }
                          >
                            <div>复制链接</div>
                          </CopyToClipboard>
                        </>
                      ) : undefined,
                    }
                  );
                }}
              >
                <div className="icon">
                  <img src={app.appinfo.gameIcon} alt="" />
                </div>
                <div className="data">
                  <div className="name">{app.appinfo.gameName}</div>
                  <div className="desc">
                    更多游戏、更多福利尽在{app.appinfo.gameName}盒子
                  </div>
                  <div className="tags">
                    <div className="tag">BT游戏</div>
                    <div className="tag">GM特权</div>
                    <div className="tag">648充值</div>
                  </div>
                </div>
                <div className="opt">
                  <div className="btn">官方下载</div>
                </div>
              </SchemeLink>
            </>
          ) : (
            <>
              <div className="option">
                <div className="title">更多服务</div>
                <div className="btns">
                  <MaybeCopyToClipboard
                    text={"9917游戏服务号"}
                    onCopy={() =>
                      Dialog.info(
                        <>
                          已复制微信号
                          <br />
                          请在微信中搜索
                        </>
                      )
                    }
                  >
                    <div className="item" onClick={openWx}>
                      <div className="container">
                        <div className="icon">
                          <img src="/static/img/offline/weixin.png" alt="" />
                        </div>
                        <div className="info">
                          <div className="text">微信咨询</div>
                          <div className="desc">点击咨询客服</div>
                        </div>
                      </div>
                    </div>
                  </MaybeCopyToClipboard>
                  <SchemeLink
                    className="item"
                    scheme={`box9917://`}
                    onClick={
                      app.branch === "old"
                        ? openBoxApp2
                        : app.osType === "ios"
                        ? () =>
                            (window.location.href = `sdk://browser?url=${encodeURIComponent(
                              `https://app.9917.cn?a=${app.agent}&brand=${app.brand}&direct=1`
                            )}`)
                        : null
                    }
                    error={() => {
                      Dialog.alert(
                        "提示",
                        "还未安装游戏盒子，立即下载？",
                        sb250 ? () => {} : undefined,
                        {
                          btn: sb250 ? (
                            <>
                              <CopyToClipboard
                                text={`https://app.9917.cn?a=${app.agent}&brand=${app.brand}`}
                                onCopy={() =>
                                  Dialog.info(
                                    <>
                                      已复制链接
                                      <br />
                                      请在浏览器中打开
                                    </>
                                  )
                                }
                              >
                                <div>复制链接</div>
                              </CopyToClipboard>
                            </>
                          ) : undefined,
                        }
                      );
                    }}
                  >
                    <div className="container" onClick={() => log("app")}>
                      <div className="icon">
                        <img src="/static/img/offline/app.png" alt="" />
                      </div>
                      <div className="info">
                        <div className="text">下载游戏盒子</div>
                        <div className="desc">点击获取更多游戏</div>
                      </div>
                    </div>
                  </SchemeLink>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default () => {
  const [app] = useActiveState("app");
  const [state, setState] = useState(true);

  return state ? (
    <Real
      close={() => {
        if (app.branch === "old") {
          window.history.back();
        } else {
          setState(false);
        }
      }}
    />
  ) : null;
};
